.app {
  text-align: center;
  background: #0a100d;
  min-height: 100vh;
  font-family: PingFang SC;
  min-width: 1400px;
  overflow: auto;
}

a {
  text-decoration: none;
  cursor: pointer;
  display: flex;
}

ul {
  padding: 0;
  margin: 0;
}

.app-header {
  background: #210791;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  width: 30%;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-logo {
  width: 68px;
}

.app-logo-text {
  width: 120px;
  margin-left: 20px;
}

.main-nav {
  margin-left: 1.5rem;
}

.nav-list {
  display: flex;
}

.nav-item {
  list-style: none;
  padding: 0 1.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-item {
  font-size: 1rem;
  color: white;
  font-weight: 600;
}

.link-icon {
  width: 70px;
}

.first-banner {
  display: flex;
  padding: 40px 15vw;
  justify-content: space-between;
  background-image: url(../img/banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
}

.first-banner .banner-title {
  text-align: left;
  z-index: 1;
  display: flex;
  position: relative;
}

.first-banner .banner-title img {
  width: 80%;
}

.first-banner .banner-title .banner-gif {
  width: 28%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.banner-text {
  margin-top: 40px;
  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.banner-text-container {
  position: relative;
  padding: 20px;
  width: 80%;
  margin-top: -40px;
  margin-left: auto;
  margin-right: auto;
}

.banner-text-container .banner-bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.banner-text-container .banner-bg img {
  width: 100%;
  height: 100%;
}

.banner-text-container .banner-text {
  position: relative;
  z-index: 1;
  text-align: center;
}

.banner-button {
  position: relative;
}

.button-bg {
  width: 218px;
}

.button-text {
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  position: absolute;
  left: 74px;
  top: 15px;
}

.second-banner {
  display: flex;
  padding: 40px;
  justify-content: space-between;
  position: relative;
}

.second-banner .banner-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.second-banner .banner-bg img {
  width: auto;
  height: 600px;
}

.second-banner .banner-main {
  height: 600px;
  width: 1350px;
  margin: auto;
}

.second-banner .banner-main .banner-text {
  width: 600px;
  margin-top: 90px;
  margin-left: 100px;
}

.third-banner {
  width: 100%;
  margin: 0 auto;
}

.third-banner img {
  width: 100%;
  max-width: 100%;
}

.four-banner {
  width: 100%;
  text-align: left;
  margin: 0 auto;
  padding: 40px 0;
}

.banner-title {
  text-align: center;
}
.four-banner .banner-title img {
  width: 564px;
}
.banner-des {
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.01em;
  text-align: center;
  color: white;
  padding: 0 100px;
}

.banner-container {
  position: relative;
  width: 1300px;
  margin: 100px auto;
  transform: scale(0.9);
}
.banner-container .banner-bg {
  position: relative;
  z-index: 0;
}

.banner-container .banner-bg img {
  width: 100%;
  height: 490px;
}

.four-banner .banner-left {
  width: 870px;
  position: relative;
  z-index: 1;
  border: 3px solid #dfecff;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 40px;
}

.four-banner .banner-right {
  background-image: url(../img/banner-bg-3.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
  top: -60px;
  right: 0;
  padding: 60px 10px;
  width: 620px;
  white-space: normal;
}

.four-banner .banner-left img {
  height: 90%;
}

.banner-chart-item {
  margin-bottom: 30px;
}

.serial-number {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: white;
  border: 1px solid #4c80ee;
  border-radius: 50%;
  width: 24px;
  display: inline-flex;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  gap: 40px;
  justify-content: center;
}

.banner-token-button {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #dfecff;
  border-radius: 30px;
  width: 186px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 13px 11.399999618530273px 0px #dafffd38 inset;
  cursor: pointer;
  z-index: 1;
  background: linear-gradient(
    90.83deg,
    rgba(20, 228, 254, 0.2) 0.71%,
    rgba(87, 161, 255, 0.2) 29.64%,
    rgba(126, 121, 255, 0.2) 78.72%,
    rgba(219, 36, 243, 0.2) 104.03%
  );
}

.chart-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-left: 15px;
}

.five-banner {
  width: 70vw;
  text-align: left;
  margin: 0 auto;
  padding: 40px 0;
}

.banner-link {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 3vw;
}

.app-footer {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  padding: 30px;
  background: #210791;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.banner-logo-3 {
  display: none;
}

.banner-logo-2 {
  width: 100%;
}

.token-modal-container {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}
.modal-content {
  max-width: 600px;
  width: 100%;
  height: 360px;
  background: #4b5482;
  position: relative;
  z-index: 1;
  border-radius: 8px;
  padding: 20px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.title-close {
  cursor: pointer;
}

.modal-body {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  overflow-wrap: anywhere;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-button {
  font-size: 16px;
  width: 146px;
  height: 36px;
  background: linear-gradient(90.83deg, #3c8aff 0.71%, #2151cb 104.03%);
  box-shadow: 0px -7px 4px 0px #292fb340 inset;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  cursor: pointer;
}
