@media (max-width: 1400px) {
  .app {
    min-width: auto;
  }
  .app-header {
    justify-content: left;
  }
  .header-container {
    width: 100%;
  }
  .header-left {
    width: 100%;
    margin-left: 20px;
  }
  .main-nav {
    display: none;
  }
  .header-right {
    display: none;
  }
  .mb-first-banner {
    display: flex;
    flex-direction: column;
    background-image: url(../img/banner-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mb-first-banner .banner-title {
    position: relative;
    width: 100%;
  }
  .mb-first-banner .banner-title .banner-title-img {
    position: relative;
    z-index: 1;
    margin-top: 40px;
    width: 90%;
    max-width: 600px;
  }

  .mb-first-banner .banner-text-container {
    width: 720px;
    max-width: 80%;
  }

  .banner-logo-container {
    position: relative;
    margin-top: -50px;
  }
  .banner-logo-container img {
    max-width: 100%;
    margin: 0 auto;
  }
  .trapezoid {
    border-width: 0 0 50px 100vw;
  }

  .mb-second-banner {
    padding: 20px 0;
    flex-direction: column;
  }

  .mb-second-banner .banner-title {
    margin-bottom: 20px;
  }

  .mb-second-banner .banner-title img {
    max-width: 80%;
  }

  .mb-second-banner .banner-main .banner-text {
    width: 500px;
    max-width: 70%;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    background-image: url(../img/mb-banner-3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px;
  }
  .mb-four-banner {
    padding: 20px 0;
    flex-direction: column;
  }

  .mb-four-banner .banner-title {
    margin-bottom: 20px;
  }

  .mb-four-banner .banner-title img {
    max-width: 80%;
  }

  .mb-banner-first {
    border: 2px solid #dfecff;
    max-width: 90%;
    margin: 0 auto;
    width: 720px;
    padding: 0 20px 50px;
    box-sizing: border-box;
  }

  .mb-banner-first img {
    width: 100%;
  }
  .mb-banner-des {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #fff;
  }
  .mb-banner-second {
    position: relative;
  }
  .banner-chart-bg {
    position: absolute;
  }
  .button-container {
    flex-direction: column;
    padding-bottom: 50px;
  }

  .banner-chart-container {
    max-width: 95%;
    width: 750px;
    position: relative;
    margin: 0 auto;
    background-image: url(../img/mb-banner-1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px 30px 40px 30px;
    box-sizing: border-box;
    margin-top: -25px;
    text-align: left;
  }
}
